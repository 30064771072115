<div class="projectable-form-wrapper"
     (keyup.enter)="save($event)"
     (keyup.escape)="returnToListPage()"
     tabindex="0"
>
    <lib-form-header
        [data]="formData"
        [title]="(formData.id ? 'Edit Service' : 'Create Service')"
        [moreActions]="formData.moreActions"
        (actionRequested)="headerActionRequested($event)"
        [(formView)]="formView"
        [saveDisabled]="svc.saveDisabled || formDataInvalid"
        [saveTooltip]="saveButtonTooltip"
    ></lib-form-header>
    <div class="edge-router-form-container projectable-form-container">
        <div class="projectable-form-main-column form-group-row" [hidden]="formView !== 'simple'">
            <div class="form-group-column three-fifths">
                <lib-form-field-container [title]="'Service Name'" [label]="'Required'">
                    <input
                        class="form-field-input"
                        placeholder="Name this Service"
                        [ngClass]="{error: svc.errors['name']}"
                        [(ngModel)]="formData.name"
                        autofocus
                        #nameFieldInput
                    />
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Select or create Service attributes'"
                        [label]="'Optional'"
                        [contentStyle]="'z-index: 99999999'"
                        [helpText]="'Attributes are tags applied to a resource. Apply the same tag to other Services to form a group of Services.'"
                >
                    <lib-tag-selector
                            [(selectedRoleAttributes)]="formData.roleAttributes"
                            [availableRoleAttributes]="serviceRoleAttributes"
                            [placeholder]="'Add attributes to group Services'"
                    ></lib-tag-selector>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Add Configurations'"
                        (actionRequested)="attachConfig()"
                        (keyup.enter)="captureConfigEnterEvent($event)"
                        [helpText]="'Configurations are used to define how to intercept and host a service. Ziti Networks come with built-in configuration types (ie. intercept.v1 and host.v1)'"
                >
                    <div class="form-field-input-group">
                        <div class="config-title-row">
                            <span class="form-field-title">Select a Config</span>
                        </div>
                        <select
                                [(ngModel)]="svc.selectedConfigTypeId"
                                (change)="configTypeChanged($event)"
                                id="SelectedConfigType"
                                class="form-field-dropdown"
                        >
                            <option value="">Select configuration type...</option>
                            <option
                                    *ngFor="let type of svc.configTypes"
                                    [value]="type.id"
                            >
                                {{ type.name }}
                            </option>
                        </select>
                        <select
                                [(ngModel)]="svc.selectedConfigId"
                                (change)="configChanged($event)"
                                id="SelectedConfig"
                                class="form-field-dropdown"
                                [ngClass]="{'disabled': !svc.selectedConfigTypeId || svc.selectedConfigTypeId === ''}"
                        >
                            <option value="">Select configuration...</option>
                            <option value="add-new">Add a New  Config</option>
                            <option value="preview" *ngIf="svc.selectedConfigId === 'preview'">View Config Data</option>
                            <option
                                    *ngFor="let config of svc.filteredConfigs"
                                    [value]="config.id"
                            >
                                {{ config.name }}
                            </option>
                        </select>
                        <div *ngIf="!showConfigData && svc.selectedConfigId && svc.selectedConfigId !== '' && svc.selectedConfigId !== 'preview'" class="button-row-right" >
                            <div class="save-button" (click)="svc.attachConfig(svc.selectedConfigId)">
                                {{svc.attachLabel}}
                            </div>
                        </div>
                        <div [hidden]="!showConfigData" class="form-field-extended-fields" [ngClass]="{'cfg-preview': svc.selectedConfigId === 'preview'}">
                            <div *ngIf="showConfigData" class="config-title-row" style="margin-bottom: 10px;">
                                <span class="form-field-title">Config Name</span>
                                <div
                                        class="save-button"
                                        (click)="attachConfig()"
                                        *ngIf="svc.selectedConfigId !== 'preview'"
                                >{{svc.attachLabel}}
                                </div>
                            </div>
                            <input
                                    *ngIf="showConfigData"
                                    [(ngModel)]="svc.newConfigName"
                                    class="form-field-input"
                                    [placeholder]="'New Config Name'"
                                    [ngClass]="{error: svc.configErrors['name']}"
                                    style="margin-bottom: 20px;"
                            />
                            <div class="config-title-row" style="margin-bottom: 10px;">
                                <span class="form-field-title">Configuration Data</span>
                                <div class="form-header-toggle-container" *ngIf="svc.selectedConfigId !== 'preview'">
                                    <span
                                            class="toggle-option-text"
                                            [ngClass]="{'toggle-option-selected': !svc.configJsonView}"
                                            (click)="svc.toggleJSONView()"
                                    >
                                        FORM
                                    </span>
                                                <div class="form-header-toggle" (click)="svc.toggleJSONView()">
                                                    <div
                                                            class="form-toggle-switch"
                                                            [ngClass]="{'toggle-left': !svc.configJsonView, 'toggle-right': svc.configJsonView}"
                                                    >
                                                        <div class="form-toggle-indicator"></div>
                                                        <div class="form-toggle-indicator"></div>
                                                    </div>
                                                </div>
                                                <span
                                                        class="toggle-option-text"
                                                        [ngClass]="{'toggle-option-selected': svc.configJsonView}"
                                                        (click)="svc.toggleJSONView()"
                                                >
                                        JSON
                                    </span>
                                </div>
                            </div>
                            <lib-config-editor
                                [(configData)]="svc.configData"
                                [schema]="svc.selectedSchema"
                                [showJsonView]="svc.configJsonView"
                                [(configErrors)]="svc.configErrors"
                                #configEditor
                            ></lib-config-editor>
                        </div>
                    </div>
                </lib-form-field-container>
                <lib-form-field-toggle [(toggleOn)]="showMore" style="margin: 0px 10px"></lib-form-field-toggle>
                <div *ngIf="showMore" class="form-group-column">
                    <lib-form-field-container
                        [title]="'Terminator Strategy'"
                        [title2]="'Encryption'"
                        [layout]="'row'"
                        [helpText]="'Pick a strategy to determine how to rank available terminators for a service'"
                        [helpText2]="'Encrypts packets when entering or leaving the network.'"
                        class="form-field-advanced"
                    >
                        <select
                                [(ngModel)]="formData.terminatorStrategy"
                                id="authPolicyId"
                                class="form-field-dropdown"
                        >
                            <option value="">Select a Strategy....</option>
                            <option
                                    *ngFor="let strat of strategies"
                                    [value]="strat.id"
                            >
                                {{ strat.label }}
                            </option>
                        </select>
                        <div class="config-item">
                            <div class="config-container toggle-container">
                                <div class="config-container-label">Require Encryption</div>
                                <div
                                        (click)="toggleEncryptionRequired()"
                                        [ngClass]="{ on: formData.encryptionRequired }"
                                        class="toggle"
                                >
                                    <span [hidden]="!formData.encryptionRequired" class="on-label">YES</span>
                                    <span [hidden]="formData.encryptionRequired" class="off-label">NO</span>
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </div>
                    </lib-form-field-container>
                    <lib-form-field-container
                            [title]="'Custom Tags'"
                            [label]="'OPTIONAL'"
                            class="form-field-advanced"
                    >
                        <lib-custom-tags [(tags)]="formData.tags"></lib-custom-tags>
                    </lib-form-field-container>
                    <lib-form-field-container
                            [title]="'App Data'"
                            [label]="'OPTIONAL'"
                            class="form-field-advanced"
                    >
                        <lib-json-view></lib-json-view>
                    </lib-form-field-container>
                </div>
            </div>
            <div class="form-group-column two-fifths">
                <lib-form-field-container
                    [title]="'Selected Configurations'"
                    [count]="svc.addedConfigNames ? svc.addedConfigNames.length : 0"
                    [helpText]="'Preview list of all service configurations that this service is associated with.'"
                >
                    <lib-preview-list
                        [allNames]="svc.addedConfigNames"
                        [allowRemove]="true"
                        [clickable]="true"
                        [tooltip]="'View Config Data'"
                        (itemRemoved)="svc.removeConfig($event)"
                        (itemSelected)="svc.previewConfig($event)"
                    ></lib-preview-list>
                </lib-form-field-container>
                <lib-form-field-container
                        *ngIf="formData.id"
                        [title]="'Associated Service Policies'"
                        [count]="svc.associatedServicePolicies.length"
                        [helpText]="'Preview list of all service policies that this service is associated with.'"
                >
                    <lib-preview-list
                            [clickable]="false"
                            [allNames]="svc.associatedServicePolicyNames"
                    ></lib-preview-list>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'API Calls'"
                        [label]="''"
                        [class]="'api-call-container'"
                        [helpText]="'Preview of the API URL and data model that is used to create/update this service'"
                >
                    <div class="form-row">
                        <input class="form-field-input" [value]="apiCallURL" readonly/>
                        <div class="url-copy icon-copy copy" (click)="copyToClipboard(apiCallURL)"></div>
                    </div>
                    <lib-json-view *ngIf="formData" [(data)]="svc.apiData" [readOnly]="true" [showCopy]="true"></lib-json-view>
                </lib-form-field-container>
            </div>
        </div>
        <div class="form-group-column" *ngIf="formView === 'raw'">
            <lib-json-view *ngIf="formData" [(data)]="formData" [(jsonInvalid)]="formDataInvalid"></lib-json-view>
        </div>
    </div>
</div>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>